import React, { useState, useEffect, useRef } from "react";

// import app from "./firebase/app";
// import "./firebase/analytics";

import useStateRef from "react-usestateref";

import Chess from "chess.js";
// import Chessground from "react-chessground";
import { Chessboard } from "react-chessboard";
import { SimpleGrid } from "@chakra-ui/react";
// import "react-chessground/dist/styles/chessground.css";

import queen from "./images/wQ.svg";
import rook from "./images/wR.svg";
import bishop from "./images/wB.svg";
import knight from "./images/wN.svg";

import CustomModal from "./functions/CustomModal";
import GameListModal from "./functions/GameListModal";

import games from "./games/games.txt";

import Games from "./games/Games";

import pgnParser from "./functions/pgnParser";

import Helper from "./functions/Helper";
import {
  Box,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Textarea,
  useToast,
  Stack,
  VStack,
  Center,
  Image,
  Progress,
  Icon,
  Spinner,
} from "@chakra-ui/react";

import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

import {
  MdMenu,
  MdSettings,
  MdFavorite,
  MdFileUpload,
  MdLightbulbOutline,
  MdRefresh,
  MdArrowUpward,
} from "react-icons/md";

import { BiSkipNext, BiSkipPrevious, BiShuffle } from "react-icons/bi";

import { FiRotateCcw } from "react-icons/fi";

import {
  FaLightbulb,
  FaFish,
  FaPlay,
  FaStop,
  FaDiscord,
  FaChessBishop,
} from "react-icons/fa";

import { HiSwitchVertical } from "react-icons/hi";

import { createBreakpoints } from "@chakra-ui/theme-tools";

const Main = () => {
  const [chess, setChess] = useState(new Chess());
  let pgn = [
    '[Event "Casual Game"]',
    '[Site "Berlin GER"]',
    '[Date "1852.??.??"]',
    '[EventDate "?"]',
    '[Round "?"]',
    '[Result "1-0"]',
    '[White "Adolf Anderssen"]',
    '[Black "Jean Dufresne"]',
    '[ECO "C52"]',
    '[WhiteElo "?"]',
    '[BlackElo "?"]',
    '[PlyCount "47"]',
    "",
    "1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Ba5 6.d4 exd4 7.O-O",
    "d3 8.Qb3 Qf6 9.e5 Qg6 10.Re1 Nge7 11.Ba3 b5 12.Qxb5 Rb8 13.Qa4",
    "Bb6 14.Nbd2 Bb7 15.Ne4 Qf5 16.Bxd3 Qh5 17.Nf6+ gxf6 18.exf6",
    "Rg8 19.Rad1 Qxf3 20.Rxe7+ Nxe7 21.Qxd7+ Kxd7 22.Bf5+ Ke8",
    "23.Bd7+ Kf8 24.Bxe7# 1-0",
  ];
  const [moves, setMoves, movesRef] = useStateRef([]);
  const [movesVerbose, setMovesVerbose, movesVerboseRef] = useStateRef([]);
  // const [currentTurn, setCurrentTurn] = useState(0)
  const [currentTurn, setCurrentTurn, currentTurnRef] = useStateRef(0);
  const [pendingMove, setPendingMove] = useState();
  const [selectVisible, setSelectVisible] = useState(false);
  const [fen, setFen] = useState("");
  const [lastMove, setLastMove] = useState();
  const [hint, setHint] = useState();

  const [libraryDb, setLibraryDb] = useState([]);
  const [libraryGameTitles, setLibraryGameTitles] = useState([]);

  const [gamesDb, setGamesDb] = useState([]);
  const [gameTitle, setGameTitle] = useState("...");

  const [boardOrientation, setBoardOrientation] = useState(false);

  const [pgnWinner, setPgnWinner, pgnWinnerRef] = useStateRef("white");
  const [currentPlayerTurn, setCurrentPlayerTurn] = useState(false);

  const [evalText, setEvalText] = useState("...");

  const [whiteName, setWhiteName] = useState("");
  const [blackName, setBlackName] = useState("");
  const [date, setDate] = useState("");

  const [whiteElo, setWhiteElo] = useState(0);
  const [blackElo, setBlackElo] = useState(0);

  const [wrongMoveCount, setWrongMoveCount, wrongMoveCountRef] = useStateRef(0);

  const [autoplay, setAutoplay, autoplayRef] = useStateRef(false);
  const [skipping, setSkipping, skippingRef] = useStateRef(false);

  const [moveCount, setMoveCount, moveCountRef] = useStateRef(0);
  const [lastMoveEval, setLastMoveEval, lastMoveEvalRef] = useStateRef(0.3);
  const [totalMoveScores, setTotalMoveScores, totalMoveScoresRef] =
    useStateRef(0);

  const [comboText, setComboText, comboTextRef] = useStateRef(0);
  const [scoreText, setScoreText, scoreTextRef] = useStateRef(0);
  const [accuracyText, setAccuracyText, accuracyTextRef] = useStateRef(100);

  const [useEngine, setUseEngine, useEngineRef] = useStateRef(true);
  const [isEvaluating, setIsEvaluating, isEvaluatingRef] = useStateRef(true);

  const [gameMenuState, setGameMenuState] = useState("library");

  const breakpoints = createBreakpoints({
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  });

  const moveScores = {
    brilliant: 80,
    best: 80,
    great: 75,
    good: 70,
    inaccuracy: 60,
    mistake: 40,
    blunder: 20,
    missed_win: 0,
  };

  const getAccuracyEmoji = (accuracy) => {
    if (accuracy >= 90) {
      return "👍";
    } else if (accuracy >= 50) {
      return "😐";
    } else {
      return "👎";
    }
  };

  const toast = useToast();

  const customSquareStyles = {};
  if (lastMove) {
    customSquareStyles[lastMove[0]] = {
      backgroundColor: "rgba(175, 242, 104,0.5)",
    };
    customSquareStyles[lastMove[1]] = {
      backgroundColor: "rgba(175, 242, 104,0.5)",
    };
  }

  if (hint) {
    customSquareStyles[hint] = {
      backgroundColor: "lightblue",
    };
  }

  // app.initializeApp({
  //   apiKey: "AIzaSyC3-MpybtgE1YY0GgxmwWEI4NI-Ei6_JN4",
  //   authDomain: "moveguesser.firebaseapp.com",
  //   projectId: "moveguesser",
  //   storageBucket: "moveguesser.appspot.com",
  //   messagingSenderId: "386835210294",
  //   appId: "1:386835210294:web:0b7d35a8b1dee1b30a3897",
  //   measurementId: "G-LFX3G0P2Y1",
  // });

  // app.analytics();

  useEffect(() => {
    // const section = document.querySelector(".main");
    // section.scrollIntoView({ behavior: "smooth", block: "start" });
    document.body.style = "background: rgb(41 53 60 / 96%);";

    initPgnDb();

    if (!window.chessEngineWorker) {
      window.chessEngineWorker = new Worker("stockfish.asm.js");
    }

    window.chessEngineWorker.postMessage("uci");

    const a = setInterval(() => tick(), 2000);

    return () => {
      clearInterval(a);
    };
  }, []);

  const tick = () => {
    if (movesRef.current.length <= currentTurnRef.current) {
      setAutoplay(false);
    } else if (autoplayRef.current) {
      forward();
    }
  };

  const initPgnDb = async () => {
    // var files = db.split("~!@#$%^&*()_+");
    // console.log(files);
    // let title = files[1].split("\r\n")[0];
    // console.log(title);

    // for (let i in files) {
    // }

    let overallGames = [];
    let allGameTitles = [];

    for (let i = 0; i < Games.GetCount(); i++) {
      // console.log(Games.LoadGame(i));

      const title = Games.GetName(i);
      allGameTitles.push(title);

      const r = await fetch(Games.LoadGame(i));

      const db = await r.text();

      var lines = db.split("\n");
      var newFile = "";

      var count = 0;

      for (let j in lines) {
        if (lines[j].match(/^\s*$/)) {
          count += 1;
        }

        if (count >= 2) {
          count = 0;

          // if (j + 1 < lines.length && lines[j + 1].match(/^\s*$/)) {
          //   count = -1;
          //   console.log(count, "count");
          // }

          newFile += "+_)(*&^%$#@!~\n";
        } else if (count !== -1) {
          newFile += lines[j] + "\n";
        }
      }
      let dbSplit = [];

      dbSplit = newFile.split("+_)(*&^%$#@!~");

      let finalGames = [];

      for (let j in dbSplit) {
        var pgnSplit = dbSplit[j].split("\n");

        var k = pgnSplit.length;
        while (k--) {
          pgnSplit[k] = pgnSplit[k].replace("\r", "");
          if (pgnSplit[k] === "") {
            pgnSplit.splice(k, 1);
          }
        }

        finalGames.push(pgnSplit);
        if (overallGames[i] === undefined) {
          overallGames[i] = new Array(0);
        }

        overallGames[i].push(pgnSplit);
      }
    }

    setLibraryDb(overallGames);

    setLibraryGameTitles(allGameTitles);

    console.log(overallGames, "overall games");
    // console.log(overallGames[14]);

    loadRandomPgn(overallGames);
  };

  const loadRandomPgn = (overallGames) => {
    let randDb = overallGames[Math.floor(Math.random() * overallGames.length)];

    let randGame = randDb[Math.floor(Math.random() * randDb.length)];
    setGamesDb(randDb);

    setGameTitle(randGame[0].replace("[", "").replace("]", ""));

    pgn = randGame;

    loadPgn();
  };

  const loadPgn = () => {
    setAutoplay(false);
    setGameTitle(pgn[0].replace("[", "").replace("]", ""));

    chess.load_pgn(pgn.join("\n"));

    setMoves(chess.history());

    setMovesVerbose(chess.history({ verbose: true }));

    // if (chess.in_checkmate()) {
    //   if (chess.turn() == 'b'){
    //     setPgnWinner('white')
    //     setBoardOrientation(false)
    //   } else {
    //     setPgnWinner('black')
    //     setBoardOrientation(true)
    //   }
    // }

    // if (chess.turn() == "b") {
    //   setPgnWinner("white");
    //   setBoardOrientation(false);
    // } else {
    //   setPgnWinner("black");
    //   setBoardOrientation(true);
    // }
    var a = pgnParser(pgn);
    // console.log(a);

    setPgnWinner(a.winner);
    setDate(a.date);
    setBlackName(a.blackName);
    setWhiteName(a.whiteName);
    setBlackElo(a.blackElo > 0 ? a.blackElo : " ");
    setWhiteElo(a.whiteElo > 0 ? a.whiteElo : " ");

    if (pgnWinnerRef.current === "black") {
      setBoardOrientation(true);
    } else {
      setBoardOrientation(false);
    }
    reset();

    // setLastMove([])

    console.log(pgn);
    console.log(currentTurnRef.current, "current turn");

    // setCurrentTurn(0)

    if (pgnWinnerRef.current === "black") {
      setTimeout(forward(), 2500);
    }

    // stockfishCrunch(chess.fen());
    setEvalText("+0.2");
    console.log(pgn, "loaded");
  };

  const stockfishCrunch = (fen) => {
    let shown = false;

    // let finalScore = 0;
    // let scoreBefore = 0;
    // let hadMate = false;

    // if (evalText.includes("Mate in") && !shown) {
    //   var str = evalText.substring(evalText.indexOf("Mate in") + 8);

    //   let str2 = parseInt(str);
    //   console.log(str2, "mate in");
    //   hadMate = true;

    //   if (str2 > 0) scoreBefore = 20;
    //   else scoreBefore = -20;
    // } else {
    //   scoreBefore = parseFloat(evalText);
    // }

    if (fen === "")
      window.chessEngineWorker.postMessage("position fen " + chess.fen());
    else window.chessEngineWorker.postMessage("position fen " + fen);
    window.chessEngineWorker.postMessage("go depth 16");
    // setEvalText("...");

    window.chessEngineWorker.onmessage = function (event) {
      //NOTE: Web Workers wrap the response in an object.
      // console.log(event.data ? event.data : event);

      let depth = event.data.substring(event.data.indexOf(" depth") + 7);
      depth = depth.split(" ")[0];
      let depthInt = parseInt(depth);

      if (event.data.includes("mate") && !shown) {
        shown = true;
        let str = event.data.substring(event.data.indexOf("mate") + 5);
        str = str.split(" ")[0];

        let mate = parseInt(str);
        mate = chess.turn() === "b" ? mate * -1 : mate;

        setEvalText(str === "0" ? "Fin" : "Mate in " + mate);
      } else if (event.data.includes("score cp") && depthInt > 10 && !shown) {
        let str = event.data.substring(event.data.indexOf("score cp") + 9);
        str = str.split(" ")[0];

        let evalNum = 0;
        evalNum = parseFloat(str);
        evalNum = chess.turn() === "b" ? evalNum * -1 : evalNum;
        console.log("Turn: " + chess.turn());

        let t = "";
        if (evalNum > 0) {
          t += "+";
        }

        setEvalText(t + evalNum / 100);
      }
    };
  };

  const toastIdRef = React.useRef();

  const PlaySound = (soundName) => {
    let soundFile = "sounds/" + soundName + ".mp3";
    // Play sound
    if (soundFile) {
      const sound = new Audio(soundFile);
      sound.play();
    }
  };

  const EvaluateMove = (
    fen,
    from,
    to,
    isWrongMove,
    playSound,
    doNotShowPopup,
    goToNextMove
  ) => {
    // console.log("evaluating");
    let scoreBefore = 0;
    let shown = false;
    let hadMate = false;
    let moveEvalString = "";
    let soundText = "";
    let customStatus = "info";
    let descText = "";

    if (isWrongMove) {
      setWrongMoveCount(wrongMoveCountRef.current + 1);
    }

    // toastIdRef.current = toast({
    //   title: isWrongMove ? "Wrong move" : "Correct Move",
    //   description: "Stockfish evaluating...",
    //   status: "info",
    //   position: "top",
    //   isClosable: false,
    // });

    // if (evalText.includes("Mate in") && !shown) {
    //   var str = evalText.substring(evalText.indexOf("Mate in") + 8);

    //   let str2 = parseInt(str);
    //   // console.log(str2, "mate in");
    //   hadMate = true;

    //   if (str2 > 0) scoreBefore = 20;
    //   else scoreBefore = -20;
    // } else {
    //   scoreBefore = parseFloat(evalText);
    // }

    let finalScore = 0;

    // window.chessEngineWorker.postMessage("stop");
    if (fen === "")
      window.chessEngineWorker.postMessage("position fen " + chess.fen());
    else window.chessEngineWorker.postMessage("position fen " + fen);
    window.chessEngineWorker.postMessage("go depth 16");

    setIsEvaluating(true);

    window.chessEngineWorker.onmessage = function (event) {
      //NOTE: Web Workers wrap the response in an object.
      // console.log(event.data ? event.data : event);

      // if (toastIdRef.current) {
      //   toast.close(toastIdRef.current);
      // }
      // toast({
      //   title: isWrongMove ? "Wrong move" : "Correct Move",
      //   description: from + "→" + to + " would be " + finalScore,
      //   status: "info",
      //   duration: 5000,
      //   position: "top",
      //   isClosable: true,
      // });
      if (!event.data.includes(" depth 16")) return;

      setIsEvaluating(false);
      if (!shown) {
        shown = true;

        if (
          event.data.includes("score cp") &&
          event.data.includes(" depth 16")
        ) {
          var str = event.data.substring(event.data.indexOf("score cp") + 9);
          str = str.split(" ")[0];

          let evalNum = 0;
          evalNum = parseFloat(str);
          evalNum = chess.turn() ? evalNum * -1 : evalNum;

          let t = "";
          if (evalNum > 0) {
            t += "+";
          }

          finalScore = t + evalNum / 100;

          let moveEval = 0;
          console.log(
            "Score: " +
              finalScore +
              " LastMoveEval:" +
              lastMoveEvalRef.current +
              " Chess turn: " +
              chess.turn()
          );
          // if (chess.turn() === "b") {
          //   moveEval = finalScore - lastMoveEvalRef.current;
          // } else {
          //   moveEval = lastMoveEvalRef.current - finalScore;
          // }
          moveEval = finalScore - lastMoveEvalRef.current;

          // console.log(moveEval, "Move eval");

          if (moveEval < -3) {
            moveEvalString = "❓❓ Blunder";
            customStatus = "error";
            soundText = "blunder";
          } else if (moveEval < -2.25) {
            moveEvalString = "❓ Mistake";
            customStatus = "warning";
            soundText = "mistake";
          } else if (moveEval < -1.5) {
            moveEvalString = "❗❓ Inaccuracy";
            customStatus = "warning";
            soundText = "inaccuracy";
          } else if (moveEval < -1) {
            moveEvalString = "✔️ Good";
            customStatus = "info";
            soundText = "good";
          } else if (moveEval < -0.5) {
            moveEvalString = "👍 Great";
            customStatus = "info";
            soundText = "great";
          } else if (moveEval >= 1.5) {
            moveEvalString = "❗❗ Brilliant";
            customStatus = "success";
            soundText = "brilliant";
          } else if (moveEval >= -0.5) {
            moveEvalString = "⭐ Best";
            customStatus = "success";
            soundText = "best";
          }

          descText = playSound
            ? moveEval.toFixed(2)
            : "Master move, " + moveEval.toFixed(2);

          if (!isWrongMove || playSound === false)
            setLastMoveEval(evalNum / 100);

          console.log("settingLastMoveEval to " + lastMoveEvalRef.current);
        }

        if (event.data.includes("mate") && event.data.includes(" depth 16")) {
          shown = true;
          var str = event.data.substring(event.data.indexOf("mate") + 5);
          str = str.split(" ")[0];

          let mate = parseInt(str);
          // mate = chess.turn() === "b" ? mate * -1 : mate;

          finalScore = str === "0" ? "" : "Mate in " + mate;

          if (isWrongMove) {
            descText += "Wrong move but still mating.";
          }

          moveEvalString = "⭐ Best";
          customStatus = "success";
          soundText = "best";

          if (!isWrongMove) {
            if (chess.turn()) {
              setLastMoveEval(100);
            } else {
              setLastMoveEval(-100);
            }
          }
        }

        if (playSound) PlaySound(soundText);

        if (wrongMoveCountRef.current == 1 && isWrongMove) {
          setTotalMoveScores(
            totalMoveScoresRef.current + moveScores[soundText]
          );
          setMoveCount(moveCountRef.current + 1);
          let averageAccuracyScore =
            ((totalMoveScoresRef.current / moveCountRef.current) * 100) /
            moveScores["best"];
          setAccuracyText(averageAccuracyScore.toFixed(1));
        } else if (!isWrongMove) {
          setTotalMoveScores(totalMoveScoresRef.current + moveScores["best"]);
          setMoveCount(moveCountRef.current + 1);
          let averageAccuracyScore =
            ((totalMoveScoresRef.current / moveCountRef.current) * 100) /
            moveScores["best"];
          setAccuracyText(averageAccuracyScore.toFixed(1));
        }

        setScoreText(
          scoreTextRef.current + comboTextRef.current * moveScores[soundText]
        );

        // console.log(moveEvalString);
        if (isWrongMove && (soundText === "good" || soundText === "great"))
          onHintClick();

        // let desc =
        //   from +
        //   "→" +
        //   to +
        //   " gained " +
        //   moveEval.toFixed(2) +
        //   " points of evaluation! " +
        //   isWrongMove
        //     ? "However it wasn't the move played."
        //     : "";
        // if (moveEval < 0)
        //   desc =
        //     from +
        //     "→" +
        //     to +
        //     " lost " +
        //     moveEval.toFixed(2) * -1 +
        //     " points of evaluation. ";

        if (hadMate) descText += " Lost a mating sequence.";

        if (isWrongMove) {
          if (wrongMoveCountRef.current >= 5) {
            descText += " Skipping.";
          } else if (wrongMoveCountRef.current >= 3) {
            descText +=
              " Hint: " + movesVerboseRef.current[currentTurnRef.current].from;
            onHintClick();
          } else {
            // desc +=
            //   moveEval < -0.25
            //     ? " This wasn't the move played in the game, try another."
            //     : " Skipping.";
          }
        }

        const ct = currentTurnRef.current;

        if (
          (isWrongMove &&
            !skippingRef.current &&
            (soundText == "best" || soundText == "brilliant")) ||
          (isWrongMove && wrongMoveCountRef.current >= 5)
        ) {
          setSkipping(true);
          setTimeout(() => {
            if (ct === currentTurnRef.current) {
              forward(true);
              setTimeout(() => {
                forward(true, true);
                setSkipping(false);
              }, 2000);
            } else {
              setSkipping(false);
            }
          }, 2000);
          setWrongMoveCount(0);
        }

        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        if (!doNotShowPopup) {
          toast({
            title: moveEvalString,
            description: descText,
            status: customStatus,
            duration: 2000,
            position: "top",
            isClosable: false,
          });
        }

        if (isWrongMove) {
          if (
            soundText === "blunder" ||
            soundText === "inaccuracy" ||
            soundText === "mistake"
          ) {
            setComboText(Math.ceil(comboText / 2));
            // setComboText(0);
            // setComboTextVisible(false);
          } else {
            // setComboTextVisible(comboTextRef.current === -1 ? false : true);
            setComboText(comboTextRef.current + 1);
          }
        }

        window.chessEngineWorker.postMessage("stop");

        if (isWrongMove) {
          chess.undo();
          setFen(chess.fen());
        }
        if (goToNextMove) forwardManual(from, to, true);
      }
    };
  };

  // await engine.set_position(fen ?? chess.fen());
  // const bestMove = engineMove(await engine.go_time(1000));
  // console.log(bestMove)

  // let evalu =  engineEval(await engine.go_time_eval(18));
  // console.log(evalu)
  // let t = ""
  // if (evalu > 0) {
  //   t += "+"
  // }
  // t = t + (evalu / 100)

  // setEvalText(t)

  function getCurrentMove() {
    return movesVerboseRef.current[currentTurnRef.current];
  }

  const onMove = async (from, to, piece) => {
    console.log(from, to, piece);
    console.log(getCurrentMove());
    if (chess.move({ from, to, promotion: "q" }) == null) return;

    setHint(null);

    if (
      from === movesVerboseRef.current[currentTurnRef.current].from &&
      to === movesVerboseRef.current[currentTurnRef.current].to
    ) {
      // window.chessEngineWorker.postMessage("stop");
      // Correct move

      for (let i = 0, len = movesVerbose.length; i < len; i++) {
        /* eslint-disable-line */

        // console.log(movesVerbose[i], "flags");
        if (
          movesVerboseRef.current[currentTurnRef.current].flags.indexOf("p") !==
            -1 &&
          movesVerboseRef.current[currentTurnRef.current].piece === "p"
        ) {
          setPendingMove([from, to]);
          setSelectVisible(true);
          return;
        }
      }

      setFen(chess.fen());
      checkEndOfGame(1);

      setWrongMoveCount(0);

      setComboText(comboTextRef.current + 1);

      EvaluateMove(chess.fen(), from, to, false, true, false, true);

      const moveAccuracy = 75; // Replace this with the actual accuracy value

      // Get the accuracy emoji
      const accuracyEmoji = getAccuracyEmoji(moveAccuracy);

      //

      // if (turnColor() !== pgnWinnerRef.current) {
      //   // setComboTextVisible(comboTextRef.current === -1 ? false : true);

      //   setComboText(comboTextRef.current + 1);
      // }

      // chess.move({ from, to, promotion: "x" });

      // setFen(chess.fen());

      // setLastMove([from, to]);

      // forward2();

      // const moves = chess.moves({ verbose: true })
      // for (let i = 0, len = moves.length; i < len; i++) { /* eslint-disable-line */
      //   if (moves[i].flags.indexOf("p") !== -1 && moves[i].from === from) {
      //     setPendingMove([from, to])
      //     setSelectVisible(true)
      //     return
      //   }
      // }
      // if (chess.move({ from, to, promotion: "x" })) {
      //   setFen(chess.fen())
      //   setLastMove([from, to])
      //   setTimeout(randomMove, 500)
      // }
    } else {
      chess.move({ from, to });

      setFen(chess.fen());
      let tempFen = chess.fen();

      // console.log("Not the move, get stockfish to evaluate");
      // console.log(chess.fen());

      // await engine.set_position(chess.fen());
      // const bestMove = engineMove(await engine.go_time(1000));

      // console.log("best move: " + bestMove);

      setTimeout(function () {
        // chess.undo();
        setFen(chess.fen());

        setLastMove([]);
        if (currentTurn > 0)
          setLastMove([
            movesVerbose[currentTurn - 1].from,
            movesVerbose[currentTurn - 1].to,
          ]);
        EvaluateMove(tempFen, from, to, true, true);
      }, 1500);
    }
  };

  // const engineMove = (moveString) => {
  //   /**
  //    * Engine reply format: bestmove e6f5 ponder a1b2,
  //    * where e6 is sqare_from, f5 is square_to
  //    * */
  //   const moveInfo = moveString.split(' ')[1]; //e6f5
  //   const from = moveInfo.substr(0, 2); //e6
  //   const to = moveInfo.substr(2, 2); //f5
  //   return {
  //     from,
  //     to,
  //     promotion: 'q',
  //   };
  // };

  // const getStockfishMove = async () => {
  //   await engine.set_position(chess.fen());
  //   const bestMove = engineMove(await engine.go_time(1000));
  //   console.log(bestMove);
  // }

  const randomMove = () => {
    const moves = chess.moves({ verbose: true });
    const move = moves[Math.floor(Math.random() * moves.length)];
    if (moves.length > 0) {
      chess.move(move.san);
      setFen(chess.fen());
      setLastMove([move.from, move.to]);
    }
  };

  const promotion = (e) => {
    const from = pendingMove[0];
    const to = pendingMove[1];

    console.log(movesVerbose[currentTurnRef.current], "promotion move");

    if (movesVerbose[currentTurnRef.current].promotion === e) {
      chess.move({ from, to, promotion: e });
      setFen(chess.fen());
      setLastMove([from, to]);
      setSelectVisible(false);
      setTimeout(forwardManual(from, to), 500);
    } else {
      chess.move({ from, to, promotion: e });

      setFen(chess.fen());
      let tempFen = chess.fen();

      console.log("Not the right promotion move, get stockfish to evaluate");

      chess.undo();
      // setFen(chess.fen());

      // setLastMove([]);

      // if (currentTurn > 0)
      //   setLastMove([
      //     movesVerbose[currentTurn - 1].from,
      //     movesVerbose[currentTurn - 1].to,
      //   ]);

      EvaluateMove(tempFen, from, to, true, true);
      // setSelectVisible(false);
    }

    // setTimeout(randomMove, 500);
  };

  const turnColor = () => {
    return chess.turn() === "w" ? "white" : "black";
  };

  const orientation = () => {
    return boardOrientation;
  };

  const calcMovable = () => {
    const dests = new Map();
    chess.SQUARES.forEach((s) => {
      const ms = chess.moves({ square: s, verbose: true });
      if (ms.length)
        dests.set(
          s,
          ms.map((m) => m.to)
        );
    });

    return {
      free: false,
      dests,
      color: skippingRef.current ? skippingRef.current : pgnWinnerRef.current,
    };
  };

  const onForwardClick = () => {
    forward();
  };

  const onBackClick = () => {
    // console.log(chess.header())

    backward();
  };

  const onResetClick = () => {
    reset();
  };

  const onAutoPlayClick = () => {
    if (movesRef.current.length > currentTurnRef.current)
      setAutoplay(!autoplayRef.current);
  };

  const onFlipClick = () => {
    setBoardOrientation(!boardOrientation);
  };

  const onHintClick = () => {
    setHint(movesVerbose[currentTurn].from);
  };

  const onUseEngineClick = () => {
    setUseEngine(!useEngineRef.current);
  };

  const reset = () => {
    chess.reset();
    setFen(chess.fen());
    setCurrentTurn(0);
    setLastMove([]);
    setEvalText("+0.3");
    setWrongMoveCount(0);
    setComboText(0);
    setSkipping(false);
    setMoveCount(0);
    setTotalMoveScores(0);
    setComboText(0);
    setScoreText(0);
    setAccuracyText(100);
    window.chessEngineWorker.postMessage("stop");
    setIsEvaluating(false);
    setLastMoveEval(0.3);
  };

  const forward = (crunch, doNotShowPopup) => {
    if (movesRef.current.length < currentTurnRef.current + 1) return;

    // console.log(currentTurn)
    // console.log("forward called " + moves[currentTurnRef.current])
    chess.move(movesRef.current[currentTurnRef.current]);
    setFen(chess.fen());

    setLastMove([
      movesVerboseRef.current[currentTurnRef.current].from,
      movesVerboseRef.current[currentTurnRef.current].to,
    ]);

    setCurrentTurn(currentTurnRef.current + 1);

    if (
      crunch &&
      movesVerboseRef.current[currentTurnRef.current] !== undefined &&
      movesVerboseRef.current[currentTurnRef.current].from !== undefined &&
      movesVerboseRef.current[currentTurnRef.current].to !== undefined
    ) {
      EvaluateMove(
        chess.fen(),
        movesVerboseRef.current[currentTurnRef.current].from,
        movesVerboseRef.current[currentTurnRef.current].to,
        false,
        false,
        doNotShowPopup
      );
    }

    checkEndOfGame();
    // setCurrentPlayerTurn(!currentPlayerTurn)

    // stockfishCrunch(chess.fen());
  };

  const forwardManual = (from, to, crunch) => {
    if (
      movesRef.current.length < currentTurnRef.current + 2 &&
      movesRef.current.length >= currentTurnRef.current + 1
    ) {
      chess.move(moves[currentTurnRef.current]);
      setFen(chess.fen());
      // stockfishCrunch(chess.fen());
      // EvaluateMove(chess.fen(), from, to, false, true, true);
      return;
    }
    if (movesRef.current.length < currentTurnRef.current + 2) return;

    // stockfishCrunch(chess.fen());
    EvaluateMove(chess.fen(), from, to, false, false, true);
    console.log("opponent move");
    chess.move(movesRef.current[currentTurnRef.current + 1]);
    setFen(chess.fen());

    setLastMove([
      movesVerboseRef.current[currentTurnRef.current + 1].from,
      movesVerboseRef.current[currentTurnRef.current + 1].to,
    ]);

    setCurrentTurn(currentTurnRef.current + 2);

    checkEndOfGame();
  };

  const backward = () => {
    if (currentTurnRef.current === 0) return;

    chess.undo();
    setFen(chess.fen());

    setCurrentTurn(currentTurnRef.current - 1);

    if (currentTurnRef.current === 0) {
      setLastMove([]);
      return;
    }
    setLastMove([
      movesVerbose[currentTurnRef.current - 1].from,
      movesVerbose[currentTurnRef.current - 1].to,
    ]);

    stockfishCrunch(chess.fen());
  };

  /// Helpers:

  const checkEndOfGame = (offset = 0) => {
    if (movesRef.current.length <= currentTurnRef.current + offset) {
      const convertAccuracyToRating = (accuracy) => {
        if (accuracy < 0 || accuracy > 100) {
          throw new Error("Accuracy must be between 0% and 100%.");
        }

        if (accuracy <= 50) {
          // Linear interpolation between 0% (rating 0) and 50% (rating 1000)
          return (accuracy / 50) * 1000;
        } else if (accuracy <= 90) {
          // Linear interpolation between 50% (rating 1000) and 90% (rating 2300)
          return 1000 + ((accuracy - 50) / 40) * 1300;
        } else {
          // Linear interpolation between 90% (rating 2300) and 100% (rating 3000)
          return 2300 + ((accuracy - 90) / 10) * 700;
        }
      };

      toast({
        title: "End of game.",
        description:
          "🎉 Congratulations! Your rating for this game is " +
          convertAccuracyToRating(accuracyTextRef.current).toFixed(0) +
          " ELO and your final score is: " +
          scoreTextRef.current,
        status: "success",
        duration: 30000,
        position: "top",
        isClosable: true,
      });
    }
  };

  // Modal code
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenGameList,
    onOpen: onOpenGameList,
    onClose: onCloseGameList,
  } = useDisclosure();

  let [pgnValue, setPgnValue] = React.useState("");

  let handlePgnInputChange = (e) => {
    let inputValue = e.target.value;
    setPgnValue(inputValue);
  };

  const loadPgnBtn = () => {
    // alert(pgnValue);
    pgn = pgnValue.split("\n");
    loadPgn();
    onClose();
    reset();
  };

  const test = () => {
    console.log(chess.pgn());
  };

  const gameListRef = useRef();
  const loadGame = (gameNumber) => {
    pgn = gamesDb[gameNumber];
    loadPgn();
    // onClose();

    onCloseGameList();
    // gameListRef.current.forceClose();
    // if (gameListRef.current !== undefined) gameListRef.current.forceClose();
  };

  const loadGameList = (int) => {
    setGameMenuState("gameList");
    setGamesDb(libraryDb[int]);
  };

  const onClickBackToLibrary = () => {
    setGameMenuState("library");
  };

  const onShuffleClick = () => {
    loadRandomPgn(libraryDb);
  };

  const loadPgnRef = useRef();

  if (blackName === "") {
    // if your component doesn't have to wait for an async action, remove this block
    return (
      <VStack width="100vw" height="100vh">
        <Center>
          <Spinner
            position="absolute"
            top="0"
            bottom="0"
            marginTop="auto"
            marginBottom="auto"
            size="xl"
            color="white"
          />
        </Center>
      </VStack>
    ); // render null when app is not ready
  }

  return (
    <div>
      {/* <br/><br/> */}
      <Box
        id="header"
        pt={{ base: 18, md: 27 }}
        width="100%"
        background="#151d4e"
        height={{ base: "130px", md: "90px" }}
        pb={{ base: -3, md: 0 }}
      >
        <Stack
          ml={25}
          direction="row"
          spacing={{ base: 0, md: 4 }}
          center="true"
          float="left"
        >
          <Link textDecoration="none" src="/">
            <Image
              onClick={() => onShuffleClick()}
              src="images/logo-moveguesser.png"
              float="left"
              width="48px"
              mr={2}
              mt={-1}
            />
            <Text
              onClick={() => onShuffleClick()}
              fontWeight="bold"
              fontSize="x-large"
              color="white"
              float="left"
            >
              MoveGuesser.com
            </Text>
          </Link>
        </Stack>
        <Stack
          mr={30}
          direction="row"
          transform={{ base: "scale(0.9)", md: "scale(1)" }}
          spacing={{ base: 3, md: 4 }}
          center="true"
          mt={{ base: 4, md: 0 }}
          ml={{ base: 0, md: 0 }}
          float={{ base: "left", md: "right" }}
        >
          <CustomModal
            showModalButtonText="About"
            modalHeader="About"
            primaryBtnText="Join our Discord"
            modalBody={
              <div>
                A wonderful new &amp; modern site that lets you review famous
                Chess games and guess the moves the masters have played from
                start to finish. Great for training a specific playstyle, or
                enjoying the show in general. No matter what your rating, you
                can benefit from and enjoy the classic games available. <br />{" "}
                <br />
                Some uses for the site:
                <br />
                - Train a specific playstyle, aggressive, positional, defensive,
                you name it. It's awesome to play the game in the perspective of
                the person you're trying to learn from.
                <br /> - Memorize a famous game and impress your friends :)
                <br />- Sit back and watch high quality and famous GM games with
                autoplay button.
                <br />- Coaches can give interactive lessons with their
                students.
                <br />
                <br />
                Developed solo by a ~2100 rated player Leon Fresh:{" "}
                <Link
                  isExternal
                  color="blue.400"
                  href="https://lichess.org/@/LeonFresh"
                >
                  https://lichess.org/@/LeonFresh
                </Link>
                <br />
                <br /> My aim is to further enhance your appreciation for the
                game of Chess and bring more people to the game, while also
                keeping this site <b>completely, absolutely, 100% free</b>. Will
                also keep adding new features based on community feedback and
                fix any bugs that you may encounter. However, this won't be
                possible without your support.
                <br />
                <br />
                If you enjoy using this site, any donation will go a long way to
                support this journey and improve the site for all lovers of
                Chess! <br />
                <br /> Please consider donating any amount you wish:
                &nbsp;&nbsp;&nbsp;
                <Link isExternal href="https://opencollective.com/moveguesser">
                  <Button
                    leftIcon={<Icon color="red.400" as={MdFavorite} />}
                    variant="solid"
                  >
                    <Text color="black" fontSize="15">
                      Sponsor
                    </Text>
                  </Button>
                </Link>
                .
                <br />
                <br />
                {/* Tons of exciting new features are planned for the future:
                <br />
                <br /> */}
                <strong>
                  <span fontSize="18px">Phase One Roadmap</span>{" "}
                </strong>
                <br />
                <br />
                <b>$0 out of $5000</b> donations received, amount updated weekly
                <Progress hasStripe value={1} />
                <br /> <b>$500:</b> Initial release, testing and bug fixes.
                Better mobile-responsiveness.
                <br />
                <br /> <b>$1000:</b> Scoring features, scores how well you do
                and saves them, for you to improve on.
                <br />
                <br />
                <b>$2000:</b> More quality curated collection of Chess games
                added to the database.
                <br />
                <br /> <b>$3000:</b> Move list with annotations
                <br />
                <br /> <b>$5000:</b> Huge membership update -- features that
                lets you save your favourite games, automatically detect and
                show games that you had trouble guessing, social features such
                as commenting, games tagging, submit your own games, or create
                collections for others to enjoy.
                <br />
                And yes it will be 100% free. <br />
                <br /> And much more, join our{" "}
                <Link
                  color="blue.400"
                  isExternal
                  href="https://discord.gg/2wxGbeagq3"
                >
                  Discord
                </Link>{" "}
                to suggest more! Stay up to date with the latest updates and
                provide feedback / suggestions or chat with the developer via
                Discord. View our{" "}
                <Link
                  color="blue.400"
                  isExternal
                  href="https://trello.com/b/MsHEi7Gt/moveguesser"
                >
                  Trello
                </Link>{" "}
                to see what we are working on currently. <br />
                <br />
                <b>Special Thanks:</b> This site wouldn't be possible without
                the help of Pawel who made{" "}
                <Link color="blue.400" isExternal href="https://chessvision.ai">
                  ChessVision.ai
                </Link>
                .
                <br />
                <Link
                  color="blue.400"
                  href="https://stockfishchess.org/"
                  isExternal
                >
                  Stockfish
                </Link>
              </div>
            }
          />

          <Button
            colorScheme="yellow"
            leftIcon={<Icon as={MdMenu} />}
            mr={2}
            onClick={onOpenGameList}
          >
            <Text fontSize="15px">Game List</Text>
          </Button>

          <Modal
            ref={gameListRef}
            colorScheme="whiteAlpha"
            scrollBehavior="inside"
            isOpen={isOpenGameList}
            onClose={onCloseGameList}
          >
            <ModalOverlay />
            <ModalContent background="#1f2225" color="#d2d2d2" maxWidth="80vw">
              <ModalHeader>
                <Text float="left">
                  {gameMenuState === "library" ? "Library" : "Game List"}
                </Text>

                <Button
                  // ref={initialRef}
                  leftIcon={<Icon as={MdArrowUpward} />}
                  colorScheme="blue"
                  ml={5}
                  size="sm"
                  float="left"
                  onClick={onClickBackToLibrary}
                  display={gameMenuState === "library" ? "none" : "block"}
                >
                  Back to Library
                </Button>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody
                display="grid"
                gridTemplateColumns={{ base: "1fr 1fr", lg: "1fr 1fr 1fr" }}
                gridAutoFlow="row dense"
                gridGap="5px"
                boxSizing="border-box"
              >
                {gameMenuState === "gameList"
                  ? gamesDb.map((item, i) => (
                      <Box onClick={() => loadGame(i)} p={2} key={i}>
                        <Text cursor="pointer">
                          <strong>
                            {i + 1}. {item[0].replace("[", "").replace("]", "")}
                          </strong>
                        </Text>
                      </Box>
                    ))
                  : libraryGameTitles.map((item, i) => (
                      <Box onClick={() => loadGameList(i)} p={2} key={i}>
                        <Text cursor="pointer">
                          <strong>
                            {item}
                            {"/"}
                          </strong>
                        </Text>
                      </Box>
                    ))}
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" onClick={onCloseGameList}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Link isExternal href="https://opencollective.com/moveguesser">
            <Button
              pr={{ base: 2, md: 4 }}
              leftIcon={<Icon color="red.400" as={MdFavorite} />}
              variant="solid"
            >
              <Text display={{ base: "none", md: "block" }} fontWeight="bold">
                Sponsor
              </Text>
            </Button>
          </Link>
        </Stack>
      </Box>

      <Center style={{ width: "100%" }}>
        <VStack
          mt={{ base: 3, md: 5 }}
          mb={12}
          direction="row"
          spacing={1}
          center="true"
        >
          <Text
            mt={{ base: 3, lg: 6 }}
            mb={{ base: 5, lg: 0 }}
            color="white"
            fontSize={{ base: "2xl", md: "4xl" }}
          >
            {gameTitle}
          </Text>

          <Text
            color="whiteAlpha.700"
            fontSize="medium"
            display={{ base: "none", lg: "block" }}
          >
            {"Date: " + date}
          </Text>
        </VStack>
      </Center>

      <Center>
        <Box className="main" position="relative">
          {/* <Chessground
            color="white"
            height="88vw"
            width="88vw"
            // height="38vw"
            // maxWidth="500px"
            // maxHeight="300px"
            orientation={orientation() ? "black" : "white"}
            turnColor={turnColor()}
            // turnColor = {currentPlayerTurn ? 'black' : 'white'}
            movable={calcMovable()}
            lastMove={lastMove}
            fen={fen}
            onMove={onMove}
            style={{ margin: "auto", maxWidth: "550px", maxHeight: "550px" }}
          /> */}
          <SimpleGrid columns={3} spacing={10}>
            <Box height="50px" textAlign="left">
              {accuracyText}% Accuracy
            </Box>
            <Box height="50px" textAlign="center">
              Combo x{comboText}
            </Box>
            <Box height="50px" textAlign="right">
              {scoreText}
            </Box>
          </SimpleGrid>

          <Box
            height={["sm", "md", "lg", "xl"]}
            width={["sm", "md", "lg", "xl"]}
          >
            <Chessboard
              height="100vw"
              width="100vw"
              position={fen}
              onPieceDrop={onMove}
              boardOrientation={orientation() ? "black" : "white"}
              customSquareStyles={customSquareStyles}
            />
          </Box>
          <Box
            width="20vw"
            height="10vw"
            position="absolute"
            minWidth="100px"
            background="white"
            top="5vw"
            marginLeft="auto"
            marginRight="auto"
            left="0"
            right="0"
            zIndex="99999"
            borderRadius="10px"
            style={{
              maxWidth: "200px",
              maxHeight: "150px",
              textAlign: "center",
              cursor: "pointer",
              display: selectVisible ? "block" : "none",
            }}
          >
            <Stack direction="row">
              <Center>
                <Box role="presentation" onClick={() => promotion("q")}>
                  <img src={queen} alt="" style={{ width: 50 }} />
                </Box>
                <Box role="presentation" onClick={() => promotion("r")}>
                  <img src={rook} alt="" style={{ width: 50 }} />
                </Box>
                <Box role="presentation" onClick={() => promotion("b")}>
                  <img src={bishop} alt="" style={{ width: 50 }} />
                </Box>
                <Box role="presentation" onClick={() => promotion("n")}>
                  <img src={knight} alt="" style={{ width: 50 }} />
                </Box>
              </Center>
            </Stack>
          </Box>
          <Box
            mr={5}
            background=""
            position="absolute"
            left={{ base: "0%", lg: "115%" }}
            top={{ base: "-10%", lg: "15%" }}
            minWidth={{ base: "100%", lg: "200px" }}
            maxWidth={{ base: "100%", lg: "250px" }}
            // style={{ maxWidth: "250px", maxHeight: "100px" }}
          >
            <Stack direction="row">
              <Center>
                <Box
                  mr={2}
                  borderRadius="10"
                  width="5vw"
                  height="5vw"
                  float="left"
                  backgroundColor="white"
                  style={{
                    backgroundImage: `url("images/portraits/${
                      pgnWinnerRef.current === "white" && !boardOrientation
                        ? Helper.toUrlPgnName(blackName)
                        : Helper.toUrlPgnName(whiteName)
                    }.jpg"), url(images/portraits/default-avatar.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    maxWidth: "60px",
                    maxHeight: "60px",
                  }}
                ></Box>
              </Center>

              <Stack direction={{ base: "row", md: "column" }}>
                <Text
                  textOverflow="ellipsis"
                  fontWeight="bold"
                  color="white"
                  fontSize={{ base: "14px", md: "17px" }}
                >
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? blackName
                    : whiteName}
                </Text>
                <Text color="white">
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? blackElo
                    : whiteElo}
                </Text>
              </Stack>

              <Text
                float="right"
                color="whiteAlpha.700"
                fontSize="medium"
                display={{ base: "block", lg: "none" }}
              >
                {date}
              </Text>
            </Stack>
          </Box>
          <Box
            width="20vw"
            height="10vw"
            mr={5}
            background=""
            position="absolute"
            left={{ base: "0%", lg: "115%" }}
            // right={{ base: "0", md: "0%" }}
            top={{ base: "105%", lg: "75%" }}
            minWidth={{ base: "100%", lg: "200px" }}
            maxWidth={{ base: "100%", lg: "250px" }}
            // style={{ maxWidth: "250px", maxHeight: "100px" }}
          >
            <Stack direction="row">
              <Center>
                <Box
                  mr={2}
                  borderRadius="10"
                  width="5vw"
                  height="5vw"
                  float="left"
                  backgroundColor="white"
                  style={{
                    backgroundImage: `url("images/portraits/${
                      pgnWinnerRef.current === "white" && !boardOrientation
                        ? Helper.toUrlPgnName(whiteName)
                        : Helper.toUrlPgnName(blackName)
                    }.jpg"), url(images/portraits/default-avatar.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    maxWidth: "60px",
                    maxHeight: "60px",
                  }}
                ></Box>
              </Center>

              <Stack direction={{ base: "row", md: "column" }}>
                <Text
                  fontWeight="bold"
                  color="white"
                  textOverflow="ellipsis"
                  fontSize={{ base: "14px", md: "17px" }}
                >
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? whiteName
                    : blackName}
                </Text>
                <Text color="white">
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? whiteElo
                    : blackElo}
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Center>

      {/* <Center>
        <Box mt={10}>
          <Text color="white" fontSize="medium">
            
          </Text>
        </Box>
      </Center> */}

      <Center>
        <VStack
          position="relative"
          pt={16}
          transform={{ base: "scale(0.9)", md: "scale(1)" }}
        >
          {/* <Box
            className="combo-counter"
            width="20vw"
            height="10vw"
            mr={5}
            background=""
            position="absolute"
            left={{ base: "95%", lg: "130%" }}
            // right={{ base: "0", md: "0%" }}
            top={{ base: "-15%", lg: "-20%" }}
            minWidth={{ base: "100%", lg: "200px" }}
            maxWidth={{ base: "100%", lg: "250px" }}
            opacity={comboTextVisibleRef.current ? 1 : 0}
            // style={{ maxWidth: "250px", maxHeight: "100px" }}
          >
            {comboText}x
          </Box> */}
          <Stack
            ml={-2}
            // mt={{ base: 20, md: 16 }}
            mb={2}
            direction="row"
            spacing={{ base: 2, md: 4 }}
            center="true"
            // maxWidth={{ base: "600px", md: "100%" }}
          >
            <Button
              colorScheme="whiteAlpha"
              mr={0}
              onClick={() => onFlipClick()}
            >
              <Icon w={5} h={5} as={HiSwitchVertical} />
            </Button>
            <Button
              colorScheme="whiteAlpha"
              mr={2}
              onClick={() => onHintClick()}
            >
              <Icon as={FaLightbulb} w={5} h={5} />
            </Button>
            <Button
              colorScheme="whiteAlpha"
              leftIcon={<Icon as={MdFileUpload} />}
              mr={2}
              onClick={onOpen}
            >
              Load PGN
            </Button>

            <Button
              colorScheme="whiteAlpha"
              mr={0}
              onClick={() => onShuffleClick()}
            >
              <Icon w={5} h={5} as={BiShuffle} />
            </Button>
            {/* <Button
              pr={{ base: 2, md: 4 }}
              pl={{ base: 2, md: 4 }}
              colorScheme="whiteAlpha"
              mr={2}
              onClick={() => onBackClick()}
            >
              <Icon as={BiSkipPrevious} w={9} h={9} />
            </Button>
            <Button
              colorScheme="whiteAlpha"
              pr={{ base: 2, md: 4 }}
              pl={{ base: 2, md: 4 }}
              mr={2}
              onClick={() => onForwardClick()}
            >
              <Icon as={BiSkipNext} w={9} h={9} />
            </Button>

            <Button
              colorScheme="whiteAlpha"
              mr={2}
              onClick={() => onAutoPlayClick()}
            >
              <Icon as={autoplayRef.current ? FaStop : FaPlay} w={4} h={4} />
            </Button>
            <Button colorScheme="whiteAlpha" onClick={() => onResetClick()}>
              <Icon as={FiRotateCcw} w={5} h={5} />
            </Button> */}
          </Stack>
          <Stack
            ml={-2}
            mt={20}
            pt={2}
            direction="row"
            spacing={4}
            center="true"
          >
            {/* <Button
              minWidth="133px"
              leftIcon={<Icon as={FaFish} />}
              onClick={() => onUseEngineClick()}
              colorScheme={useEngineRef.current ? "yellow" : "whiteAlpha"}
            >
              {useEngine ? evalText : "Off"}
            </Button> */}
          </Stack>
        </VStack>
      </Center>

      <Center>
        <Text mt={20} color="whiteAlpha.600">
          Copyright © 2021 MoveGuesser.com, All rights reserved.
        </Text>
      </Center>

      <Modal initialFocusRef={loadPgnRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Load PGN</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              ref={loadPgnRef}
              value={pgnValue}
              onChange={handlePgnInputChange}
              placeholder="Paste the PGN"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => loadPgnBtn()}>
              Load
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Main;
