import g1 from "./famous-games.txt";
import g2 from "./opening-traps.txt";
import g3 from "./morphy.txt";
import g4 from "./judit-polgar.txt";
import g5 from "./hikaru-nakamura.txt";
import g6 from "./robert-james-fischer.txt";
import g7 from "./tal.txt";
import g8 from "./kasparov.txt";
import g9 from "./karpov.txt";
import g10 from "./magnus-carlsen.txt";
import g11 from "./botvinnik.txt";
import g12 from "./capablanca.txt";
import g13 from "./lasker.txt";
import g14 from "./alekhine.txt";
import g15 from "./anand.txt";
import g16 from "./kramnik.txt";
import g17 from "./steinitz.txt";
import g18 from "./petrosian.txt";
import g19 from "./eric-hansen.txt";
import g20 from "./danya.txt";
import g21 from "./anish.txt";
import g22 from "./bartholomew.txt";
import g23 from "./queens-gambit.txt";
import g24 from "./kasparov-on-my-great-predecessors-1.txt";
import g25 from "./60-memorable-games.txt";

export default class Games {
  static allGames = [
    g1,
    g2,
    g3,
    g4,
    g5,
    g6,
    g7,
    g8,
    g9,
    g10,
    g11,
    g12,
    g13,
    g14,
    g15,
    g16,
    g17,
    g18,
    g19,
    g20,
    g21,
    g22,
    g23,
    g24,
    g25,
  ];
  static gameNames = [
    "Famous Games",
    "Opening Traps",
    "Paul Morphy",
    "Judit Polgar",
    "Hikaru Nakamura",
    "Robert James Fischer",
    "Mikhail Tal",
    "Garry Kasparov",
    "Anatoly Karpov",
    "Magnus Carlsen",
    "Mikhail Botvinnik",
    "Jose Raul Capablanca",
    "Emanuel Lasker",
    "Alexander Alekhine",
    "Viswanathan Anand",
    "Vladimir Kramnik",
    "Wilhelm Steinitz",
    "Tigran V Petrosian",
    "Eric Hansen",
    "Daniel Naroditsky",
    "Anish Giri",
    "John Bartholomew",
    "Queens Gambit Series",
    "Kasparov's Book - Great Predecessors",
    "Fischer's Book - 60 Memorable Games",
  ];

  static LoadGame(i) {
    return Games.allGames[i];
  }

  static GetCount() {
    return Games.allGames.length;
  }

  static GetName(i) {
    return Games.gameNames[i];
  }
}
