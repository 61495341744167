export default function pgnParser(pgnGameStrings) {
  let whiteName = "";
  let blackName = "";
  let whiteElo = 0;
  let blackElo = 0;
  let date = "";
  let winner = "white";

  function reverseNameCheck(input) {
    let str2 = input;
    if (input.includes(",") && input.indexOf(",") > 0) {
      let str3 = input.replace(",", " ").split(" ")[0];
      //   console.log(str);
      str2 = input.substring(input.indexOf(str3 + 1));
      str2 = str2.split(" ")[1] + " " + str3.split(" ")[0];
    }
    return str2;
  }

  for (let i in pgnGameStrings) {
    let t = pgnGameStrings[i];
    if (t.includes("[White ")) {
      var str = t.substring(t.indexOf("[White ") + 8).replace('"]', "");
      whiteName = reverseNameCheck(str);
    } else if (t.includes("[Black ")) {
      var str = t.substring(t.indexOf("[Black ") + 8).replace('"]', "");
      blackName = reverseNameCheck(str);
    } else if (t.includes("[WhiteElo ")) {
      var str = t.substring(t.indexOf("[WhiteElo ") + 11).replace('"]', "");
      whiteElo = str;
    } else if (t.includes("[BlackElo ")) {
      var str = t.substring(t.indexOf("[BlackElo ") + 11).replace('"]', "");
      blackElo = str;
    } else if (t.includes("[Date ")) {
      var str = t.substring(t.indexOf("[Date ") + 7).replace('"]', "");
      date = str;
    } else if (t.includes("[Result ")) {
      var str = t.substring(t.indexOf("[Result ") + 9).replace('"]', "");
      if (str.includes("0-1")) winner = "black";
      else if (str.includes("1-0")) winner = "white";
    }
  }

  return {
    whiteName: whiteName,
    blackName: blackName,
    whiteElo: whiteElo,
    blackElo: blackElo,
    date: date,
    winner: winner,
  };
}
