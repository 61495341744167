
import React, { useState,useEffect } from "react"

import { ChakraProvider } from "@chakra-ui/react"

import Main from './Main'
import "react-chessground/dist/styles/chessground.css"



const App = () => {

  return (
    <ChakraProvider>
      <Main/>
    </ChakraProvider>
  )
}

export default App;
