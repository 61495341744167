import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";

import { FaInfoCircle } from "react-icons/fa";

const CustomModal = ({
  showModalButtonText,
  modalHeader,
  modalBody,
  primaryBtnText,
  useFooter,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  return (
    <>
      <Button
        // background="#262672"
        leftIcon={<Icon as={FaInfoCircle} />}
        colorScheme="yellow"
        onClick={onOpen}
      >
        {showModalButtonText}
      </Button>
      <Modal
        initialFocusRef={initialRef}
        size="xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          background="#1f2225"
          color="#d2d2d2"
          maxWidth="800px"
          width="80vw"
        >
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBody}</ModalBody>

          <ModalFooter hidden={useFooter}>
            <Link href="https://discord.com/invite/2wxGbeagq3">
              <Button
                ref={initialRef}
                colorScheme="yellow"
                mr={3}
                onClick={onClose}
              >
                <Text fontSize="15px">{primaryBtnText}</Text>
              </Button>
            </Link>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;
